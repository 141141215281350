<template>
    <div>
        <b-sidebar backdrop id="view_vendor_request" width="70rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="formtitle" shadow >
            <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{formtitle}}</span>
                        </div>
                        <div @click="hide" id="sarvBtn" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>اغلاق</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="px-3 py-2" style="direction:rtl">
                        <v-simple-table striped hover style="width:100%;">
                            <thead>
                                <tr>
                                    <th class="text-center">تاريخ الارسال</th>
                                    <th class="text-center">استلام بواسطة</th>
                                    <th class="text-center">تاريخ الاستلام</th>
                                    <th class="text-center">تاريخ الارجاع</th>
                                    <th class="text-center">ارجاع بواسطة الاستلام</th>
                                    <th class="text-center">الحالة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">{{String(ginfo.reqdate).substr(0,10)}}</td>
                                    <td class="text-center">{{String(ginfo.received_date).substr(0,10)}}</td>
                                    <td class="text-center">{{ginfo.received_by}}</td>
                                    <td class="text-center">{{String(ginfo.returned_date).substr(0,10)}}</td>
                                    <td class="text-center">{{ginfo.returned_by}}</td>
                                    <td class="text-center" v-html="$parent.getStatus(ginfo.status)"></td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-simple-table striped hover style="width:100%;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">اسم الصنف</th>
                                        <th class="text-center">الكمية المرسلة</th>
                                        <th class="text-center">الاجمالي المرسل</th>
                                        <th class="text-center">الكمية المستلمة</th>
                                        <th class="text-center">الاجمالي المستلم</th>
                                        <th class="text-center">الكمية المرتجعة</th>
                                        <th class="text-center">اجمالي المرتجع</th>
                                    </tr>
                                </thead>
                                <tbody id="tablerow">
                                    <tr v-for="(item,index) in grows" :key="index">
                                        <td class="text-center">{{ item.item_name }}</td>
                                        <td class="text-center">{{ item.sent_qty }}</td>
                                        <td class="text-center">{{ item.total }}</td>
                                        <td class="text-center">{{ item.received_qty }}</td>
                                        <td class="text-center">{{ item.received_total }}</td>
                                        <td class="text-center">{{ item.returned_qty }}</td>
                                        <td class="text-center">{{ item.returned_total }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="text-center"></th>
                                        <th class="text-center">{{sentQty}}</th>
                                        <th class="text-center">{{sentTotal}}</th>
                                        <th class="text-center">{{recQty}}</th>
                                        <th class="text-center">{{recTotal}}</th>
                                        <th class="text-center">{{retQty}}</th>
                                        <th class="text-center">{{retTotal}}</th>
                                    </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" v-if="ginfo.status == 1" @click='cancelme()' class="ma-2" style="width:100px;">الغاء</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            formtitle: 'استعراض ',
            ginfo: {
                reqdate: '',
                received_date: '',
                received_by: '',
                returned_date: '',
                returned_by: '',
                status: '',
            },
            grows: [],
            reqid: [],
        }
    },
    computed:{
        sentQty: function(){
            let t = 0;
            for(let i=0;i<this.grows.length;i++){
                t = parseFloat(t) + parseFloat(this.grows[i].sent_qty);
            }
            return this.$RoundNum(t);
        },
        sentTotal: function(){
            let t = 0;
            for(let i=0;i<this.grows.length;i++){
                t = parseFloat(t) + parseFloat(this.grows[i].total);
            }
            return this.$RoundNum(t);
        },
        recQty: function(){
            let t = 0;
            for(let i=0;i<this.grows.length;i++){
                t = parseFloat(t) + parseFloat(this.grows[i].received_qty);
            }
            return this.$RoundNum(t);
        },
        recTotal: function(){
            let t = 0;
            for(let i=0;i<this.grows.length;i++){
                t = parseFloat(t) + parseFloat(this.grows[i].received_total);
            }
            return this.$RoundNum(t);
        },
        retQty: function(){
            let t = 0;
            for(let i=0;i<this.grows.length;i++){
                t = parseFloat(t) + parseFloat(this.grows[i].returned_qty);
            }
            return this.$RoundNum(t);
        },
        retTotal: function(){
            let t = 0;
            for(let i=0;i<this.grows.length;i++){
                t = parseFloat(t) + parseFloat(this.grows[i].returned_total);
            }
            return this.$RoundNum(t);
        },
    },
    methods: {
        checkData(){
            // // console.log(this.ginfo);
            // // console.log(this.grows);
            // // console.log(this.reqid);
        },
        
        cancelme(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', 'cancelVendorRequest');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[id]",this.reqid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.$parent.getRequests();
                document.getElementById('sarvBtn').click();
            });
            
        }
    },
    created() {
       // this.camp.types = {text:'رسائل واتس آب', value:'2'};
       //this.getItems();
    },
}
</script>